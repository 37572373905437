import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/inspirational-msg.jpg";

const Inepirationalmessage = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const [limit1, setLimit1] = useState(22);
  const [limit2, setLimit2] = useState(40);
  const [limit3, setLimit3] = useState(80);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    handlePreinfoSave();
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (formdata?.message_one) {
      setLimit1(22 - formdata?.message_one?.length || 0);
    } else {
      setLimit1(22);
    }
    if (formdata?.message_two) {
      setLimit2(40 - formdata?.message_two?.length || 0);
    } else {
      setLimit2(40);
    }
    if (formdata?.message_three) {
      setLimit3(80 - formdata?.message_three?.length || 0);
    } else {
      setLimit3(80);
    }
  }, [formdata]);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img
                  src={
                    "https://e-books-customization.s3.ap-southeast-1.amazonaws.com/e-book-api/thisisyourworld/final/14.png"
                  }
                  alt=""
                />
                <div className="motivational_container">
                  <p class="heart">A heart full of hope</p>
                  <p id="message_one">
                    {formdata?.message_one || "A sense of adventure"}
                  </p>
                  <p class="memories">Memories of us</p>
                  <p class="determination">Determination</p>
                  <p id="message_two">
                    {formdata?.message_two ||
                      "Hugs that warm you wherever you are"}
                  </p>
                  <p class="friendship">The friendship we share</p>
                  <p id="message_three">
                    {formdata?.message_three ||
                      "Reminder: you are always loved and never alone"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>Words of hope that would make any journey brighter.</h4>
                <p>
                  Encouraging words that bring on a smile when times get dark.
                  We’ve filled them for you, but feel free to change them if you
                  wish!
                </p>
                <div class="form-info">
                  <p>Positive messages 1</p>
                  <textarea
                    className="mini-msg m-0"
                    name="book-textarea"
                    cols=""
                    rows=""
                    maxLength={22}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        message_one: e.target.value,
                      });
                    }}
                    placeholder={"A sense of adventure"}
                  >
                    {formdata?.message_one || ""}
                  </textarea>
                  <span className="limit">{limit1}</span>
                  {alert?.message_one && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.message_one}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Positive messages 2</p>
                  <textarea
                    className="mini-msg m-0"
                    name="book-textarea"
                    cols=""
                    rows=""
                    maxLength={40}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        message_two: e.target.value,
                      });
                    }}
                    placeholder={"Hugs that warm you wherever you are "}
                  >
                    {formdata?.message_two || ""}
                  </textarea>
                  <span className="limit">{limit2}</span>
                  {alert?.message_two && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.message_two}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Positive messages 3</p>
                  <textarea
                    className="mini-msg m-0"
                    name="book-textarea"
                    cols=""
                    rows=""
                    maxLength={80}
                    onChange={(e) => {
                      mixins.preventEmoji(e);
                      handleChange({
                        ...formdata,
                        message_three: e.target.value,
                      });
                    }}
                    placeholder={
                      "Reminder: you are always loved and never alone"
                    }
                  >
                    {formdata?.message_three || ""}
                  </textarea>
                  <span className="limit">{limit3}</span>
                  {alert?.message_three && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.message_three}
                    </span>
                  )}
                </div>

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Inepirationalmessage);
