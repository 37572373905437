import React, { useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import i from "../../../../assets/images/i.png";
import location from "../../../../assets/images/location.png";
import home from "../../../../assets/images/home.png";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import Alert from "@mui/material/Alert";
import "react-phone-input-2/lib/style.css";

const Addaddresscard = (props) => {
  const { shipping, addressinfo, setAddressinfo, countrylist } = props;
  const [alertFail, setAlertFail] = useState(null);
  const [countries, setCountries] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [citylist, setCitylist] = useState([]);

  const OnHandleChangeCountry = (code) => {
    let country = countrylist.find((item) => item?.sortnam === code);
    setAddressinfo({
      ...addressinfo,
      country: country?.name,
      state: "",
      city: "",
    });
    setStatelist([]);
    setCitylist([]);
    let gstdata = {
      countryname: country?.name,
      gst_rate: country?.gst_rate || 0,
    };
    if (shipping) {
      mixins.setStore("contrywithgst", gstdata, props);
    }
    if (country?.id) {
      fetchstate(country?.id);
    }
  };

  //fetch State
  const fetchstate = (data) => {
    api
      .getstatelist(data)
      .then((res) => {
        if (res.status) {
          let tempstate = res.data.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
            };
          });
          setStatelist(tempstate);
        }
      })
      .catch((rej) => {
        console.log(rej);
      });
  };
  const handlestatechange = (newvalue) => {
    setAddressinfo({
      ...addressinfo,
      state: newvalue?.label,
      city: "",
    });
    setCitylist([]);
    let state = statelist.find((item) => item?.label == newvalue?.label);
    if (state?.value) {
      fetchcitylist(state?.value);
    }
  };

  const fetchcitylist = (id) => {
    if (id != undefined) {
      api
        .getcitylist(id)
        .then((res) => {
          if (res.status) {
            let tempcity = res.data.map((item) => ({
              value: item?.id,
              label: item?.name,
            }));
            setCitylist(tempcity);
          }
        })
        .catch((rej) => {
          console.log(rej);
        });
    } else {
      setCitylist("");
    }
  };
  const handlecitychange = (newvalue) => {
    setAddressinfo({
      ...addressinfo,
      city: newvalue?.label,
    });
  };
  useEffect(() => {
    if (countrylist.length > 0) {
      let tempcountries = [];
      if (shipping) {
        tempcountries = countrylist
          .filter((item) => item.sortnam == "SG")
          .map((item) => item?.sortnam);
      } else {
        tempcountries = countrylist.map((item) => item?.sortnam);
      }

      setCountries(tempcountries);
    }
  }, [countrylist]);

  // Fetch State

  useEffect(() => {
    if (shipping && countries.length > 0) {
      OnHandleChangeCountry("SG");
    }
  }, [countries]);

  useEffect(() => {
    if (countries.length > 0) {
      if (addressinfo?.country) {
        let country = countrylist.find(
          (item) => item?.name === addressinfo?.country
        );
        fetchstate(country?.id);
      }
    }
  }, [countries, addressinfo?.country]);

  // Fetch City
  useEffect(() => {
    if (statelist.length > 0) {
      if (addressinfo?.state) {
        let state = statelist.find((item) => item?.label == addressinfo?.state);
        fetchcitylist(state?.value);
      }
    }
  }, [statelist, addressinfo?.state]);

  return (
    <>
      {alertFail && (
        <Alert severity="error" variant="filled">
          <strong>{alertFail}</strong>
        </Alert>
      )}

      <div className="address-form-input">
        <div className="address-form-input-row">
          <i className="fas fa-user icon"></i>
          <input
            type="text"
            placeholder="First Name"
            value={addressinfo?.firstName}
            onChange={(e) => {
              setAddressinfo({
                ...addressinfo,
                firstName: e.target.value,
              });
            }}
            className="input"
          />
        </div>
        <div className="address-form-input-row">
          <input
            type="text"
            placeholder="Last Name"
            className="input-last"
            value={addressinfo?.lastName}
            onChange={(e) => {
              setAddressinfo({
                ...addressinfo,
                lastName: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="address-form-input">
        <div className="address-form-input-row">
          <img src={home} className="icon-png" />
          <textarea
            value={addressinfo?.address1}
            onChange={(e) => {
              setAddressinfo({
                ...addressinfo,
                address1: e.target.value,
              });
            }}
            type="textarea"
            placeholder="House/Apt Number, Street Name"
            className="input"
          />
        </div>
        <div className="address-form-input-row">
          <img src={location} className="icon-png" />
          <textarea
            value={addressinfo?.address2}
            onChange={(e) => {
              setAddressinfo({
                ...addressinfo,
                address2: e.target.value,
              });
            }}
            type="textarea"
            placeholder="Address line 2 (optional) "
            className="input"
          />
        </div>
      </div>
      <div className="address-form-input">
        <div className="address-form-input-row">
          <ReactFlagsSelect
            selected={
              countrylist.find((item) => item.name == addressinfo?.country)
                ?.sortnam
            }
            countries={countries}
            onSelect={(code) => OnHandleChangeCountry(code)}
            searchable={true}
            searchPlaceholder="Search countries"
            disabled={shipping}
          />
        </div>
        <div className="address-form-input-row">
          <Select
            placeholder="State"
            value={
              addressinfo?.state
                ? statelist.find(
                    (item) => item.label === addressinfo.state
                  ) || { label: addressinfo.state, value: addressinfo.state }
                : ""
            }
            options={statelist}
            onChange={handlestatechange}
            isSearchable={true}
          />
        </div>
      </div>
      <div className="address-form-input">
        <div className="address-form-input-row">
          <CreatableSelect
            placeholder="Town / City"
            value={
              addressinfo?.city
                ? citylist.find((item) => item.label === addressinfo?.city) || {
                    label: addressinfo?.city,
                    value: addressinfo?.city,
                  }
                : addressinfo?.city
                ? addressinfo?.city
                : ""
            }
            options={citylist}
            onChange={(newValue) => {
              setAddressinfo({
                ...addressinfo,
                city: newValue?.label || newValue?.value,
              });
            }}
            isSearchable={true}
          />
        </div>
        <div className="address-form-input-row">
          <input
            type="text"
            placeholder="Postcode"
            className="input-last"
            value={addressinfo?.zipCode}
            name="zip"
            minLength={6}
            maxLength={10}
            onChange={(e) => {
              setAddressinfo({
                ...addressinfo,
                zipCode: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="address-form-input">
        <div className="address-form-input-row">
          <PhoneInput
            // country={addressinfo?.country?.toLowerCase() || "sg"}
            value={addressinfo?.contactNo ? String(addressinfo?.contactNo) : ""}
            onChange={(phone) => {
              setAddressinfo({
                ...addressinfo,
                contactNo: phone,
              });
            }}
          />
        </div>
      </div>
      {shipping && (
        <div className="address-form-input">
          <div className="address-form-input-row">
            <img src={i} className="i-img" />
            <label>We'll update you on your order status</label>
          </div>
          <div className="address-form-input-row">
            <img src={i} className="i-img" />
            <label>Get free updates on where your parcel is</label>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(Addaddresscard);
