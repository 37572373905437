import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/22.png";
import ImageCropper from "./image-cropper/ImageCropper";
import mixins from "../../../../app-services/mixins";
import Alertmodal from "../Alertmodal";

const Uploadfour = (props) => {
  const {
    images,
    setImages,
    imagesassets,
    setImagesassets,
    originalImage,
    setOriginalimage,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;

  const [alert, setAlert] = useState({
    image_six: "Maximum upload size: 5MB",
    image_seven: "Maximum upload size: 5MB",
  });
  const [image1, setImage1] = useState({});
  const [image2, setImage2] = useState({});
  const nameRef = useRef();
  const [newalert, setNewalert] = useState(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!image1?.base64) {
      setAlert({
        ...alert,
        image_six: "Field is required.",
      });
      nameRef.current.focus();
    } else if (!image2?.base64) {
      setAlert({
        ...alert,
        image_seven: "Field is required.",
      });
    } else {
      setImages({
        ...images,
        image_six: image1?.file,
        image_seven: image2?.file,
      });
      setImagesassets({
        ...imagesassets,
        image_six: image1?.base64,
        image_seven: image2?.base64,
      });
      handlePreinfoSave();
    }
  };

  const handleCropCompletesix = (base64, file) => {
    mixins.compressImageToTargetSize(base64).then((res) => {
      setImage1({
        base64: res,
        file: file,
      });
    });
  };
  const handleCropCompleteseven = (base64, file) => {
    mixins.compressImageToTargetSize(base64).then((res) => {
      setImage2({
        base64: res,
        file: file,
      });
    });
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert({
          image_six: "Maximum upload size: 5MB",
          image_seven: "Maximum upload size: 5MB",
        });
      }, 2000);
    }
  }, [alert]);
  useEffect(() => {
    if (imagesassets?.image_six) {
      setImage1({
        base64: imagesassets?.image_six,
        file: images?.image_six,
      });
    }
    if (imagesassets?.image_seven) {
      setImage2({
        base64: imagesassets?.image_seven,
        file: images?.image_seven,
      });
    }
  }, []);
  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                {image1?.base64 && (
                  <img src={image1?.base64} className="uploaded_image_six" />
                )}
                {image2?.base64 && (
                  <img src={image2?.base64} className="uploaded_image_seven" />
                )}
                <img src={titleimg} className="main_image_one" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <div className="mb-2">
                  <h4>PHOTO 6</h4>
                  <p>Upload a photo with a best friend.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_six: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          handleCropCompletesix(base64String, file);
                        };

                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_six && (
                    <span
                      style={{
                        color: "red",
                      }}
                      className="mb-2"
                    >
                      {alert?.image_six}
                    </span>
                  )}
                  {originalImage?.image_six?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_six?.base64}
                          onCropComplete={handleCropCompletesix}
                          ratio={41 / 62}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h4>PHOTO 7</h4>
                  <p>Upload a photo with family.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    className="m-0"
                    onChange={(e) => {
                      let pass = mixins.filefivemb(e);
                      if (pass?.status) {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          const base64String = reader.result;
                          setOriginalimage({
                            ...originalImage,
                            image_seven: {
                              base64: base64String,
                              file: file,
                            },
                          });
                          handleCropCompleteseven(base64String, file);
                        };

                        reader.readAsDataURL(file);
                      } else {
                        setNewalert(pass.message);
                      }
                    }}
                  />
                  {alert?.image_seven && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert?.image_seven}
                    </span>
                  )}
                  {originalImage?.image_seven?.base64 && (
                    <div className="row my-2">
                      <div className="col-md-12">
                        <ImageCropper
                          image={originalImage?.image_seven?.base64}
                          onCropComplete={handleCropCompleteseven}
                          ratio={1}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                    Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alertmodal
        open={newalert}
        handleClose={() => {
          setNewalert(null);
        }}
      />
    </div>
  );
};

export default connect(Uploadfour);
